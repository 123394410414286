import React from 'react'
import { Link, graphql } from 'gatsby'
import sample from 'lodash/sample'
import Layout from '../components/layout'
import SEO from '../components/seo'
import Callout from '../components/callout'
import * as styles from './styles/index-page.module.css'
import Button from '../components/button'
import Section from '../components/section'
import ServiceCard from '../components/serviceCard'
import Card from '../components/card'
import FeaturedHomePageContent from '../components/featuredHomePageContent'
import HexagonIcon from '../components/icons/Hexagon'
import { getTextCopy, getHtmlCopy } from '../helpers'

// logos
import apsLogo from '../static/company-logos/aps.svg'
import asuLogo from '../static/company-logos/asu.svg'
import beazerLogo from '../static/company-logos/beazer.svg'
import bluecrossLogo from '../static/company-logos/bluecross.svg'
import coolClubsLogo from '../static/company-logos/cool-clubs.svg'
import ebayLogo from '../static/company-logos/ebay.svg'
import lesliesLogo from '../static/company-logos/leslies.svg'
import silverSneakersLogo from '../static/company-logos/silver-sneakers-v2.svg'
import beConnectedLogo from '../static/company-logos/beconnected.svg'
import pingLogo from '../static/company-logos/ping.svg'
import stealthLogo from '../static/company-logos/stealth.svg'
import tivityHealthLogo from '../static/company-logos/tivity-health.svg'

import RocketSVG from '../static/rocket'

const classes = [styles.spinUp, styles.spinDown]

const IndexPage = ({ data }) => {
  const {
    seoTitle,
    seoDescription,
    seoImage,
    pageSlug,
    content,
    callout,
  } = data.homePage
  const featuredContent = data.featuredContent.edges
  const services = data.services.edges
  const { servicesPageSlug } = data.servicesPage
  const { servicePageSlug } = data.servicePage
  const { contactPageSlug } = data.contactPage

  const randomFeaturedContent = sample(featuredContent).node

  return (
    <>
      <SEO
        title={seoTitle}
        description={seoDescription}
        imageUrl={seoImage.file.url}
        urlPath={pageSlug}
      />
      <Layout callout={callout && <Callout data={callout} />}>
        <Section minPadding className={styles.header}>
          <div className={styles.headerContent}>
            <h1>{getTextCopy(content, 'page.home.header')}</h1>
            <div
              dangerouslySetInnerHTML={{
                __html: getHtmlCopy(content, 'page.home.subheader'),
              }}
            />
            <Link to={servicesPageSlug}>
              <Button action="primary" className={styles.button}>
                {getTextCopy(content, 'page.home.featuredButton')}
              </Button>
            </Link>
          </div>
          <div className={styles.heroCallout}>
            <FeaturedHomePageContent content={randomFeaturedContent} />
          </div>
          <div className={styles.headerAnimation}>
            <HexagonIcon
              size={Math.floor(Math.random() * 48) + 32}
              className={classes[Math.floor(Math.random() * classes.length)]}
              style={{
                top: `-${Math.floor(Math.random() * 120) + 80}px`,
                left: `${Math.floor(Math.random() * 20) + 10}px`,
                order: `${Math.floor(Math.random() * 6) + 1}`,
                animationDuration: `${Math.floor(Math.random() * 24) + 10}s`,
              }}
            />
            <HexagonIcon
              size={Math.floor(Math.random() * 24) + 18}
              className={classes[Math.floor(Math.random() * classes.length)]}
              style={{
                top: `${Math.floor(Math.random() * 120) + 20}px`,
                left: `${Math.floor(Math.random() * 30) + 1}px`,
                order: `${Math.floor(Math.random() * 6) + 1}`,
                animationDuration: `${Math.floor(Math.random() * 20) + 10}s`,
              }}
            />
            <HexagonIcon
              size={Math.floor(Math.random() * 32) + 24}
              className={classes[Math.floor(Math.random() * classes.length)]}
              style={{
                top: `-${Math.floor(Math.random() * 150) + 10}px`,
                left: `${Math.floor(Math.random() * 30) + 10}px`,
                order: `${Math.floor(Math.random() * 6) + 1}`,
                animationDuration: `${Math.floor(Math.random() * 30) + 10}s`,
              }}
            />
            <HexagonIcon
              size={Math.floor(Math.random() * 48) + 32}
              className={classes[Math.floor(Math.random() * classes.length)]}
              style={{
                top: `-${Math.floor(Math.random() * 150) + 60}px`,
                left: `${Math.floor(Math.random() * 20) + 1}px`,
                order: `${Math.floor(Math.random() * 6) + 1}`,
                animationDuration: `${Math.floor(Math.random() * 30) + 10}s`,
              }}
            />
            <HexagonIcon
              size={Math.floor(Math.random() * 32) + 24}
              className={classes[Math.floor(Math.random() * classes.length)]}
              style={{
                top: `-${Math.floor(Math.random() * 150) + 60}px`,
                left: `${Math.floor(Math.random() * 20) + 1}px`,
                order: `${Math.floor(Math.random() * 6) + 1}`,
                animationDuration: `${Math.floor(Math.random() * 30) + 10}s`,
              }}
            />
            <HexagonIcon
              size={Math.floor(Math.random() * 24) + 18}
              className={classes[Math.floor(Math.random() * classes.length)]}
              style={{
                top: `-${Math.floor(Math.random() * 150) + 60}px`,
                left: `${Math.floor(Math.random() * 20) + 1}px`,
                order: `${Math.floor(Math.random() * 6) + 1}`,
                animationDuration: `${Math.floor(Math.random() * 30) + 10}s`,
              }}
            />
          </div>
        </Section>
        <div className={styles.workWithYou}>
          <Section className={styles.workWithYou__inner}>
            <div className={styles.workWithYou__content}>
              <h3>{getTextCopy(content, 'page.home.secondaryHeader')}</h3>
              <div
                className={styles.subheader}
                dangerouslySetInnerHTML={{
                  __html: getHtmlCopy(content, 'page.home.secondarySubheader'),
                }}
              />
            </div>
            <div className={styles.logos}>
              <img src={apsLogo} alt="APS logo" />
              <img src={asuLogo} alt="ASU logo" />
              <img src={beazerLogo} alt="Beazer logo" />
              <img src={bluecrossLogo} alt="Bluecross/Blueshield logo" />
              <img src={coolClubsLogo} alt="Cool Clubs logo" />
              <img src={ebayLogo} alt="Ebay logo" />
              <img src={lesliesLogo} alt="Leslie's Pools logo" />
              <img src={silverSneakersLogo} alt="Silver Sneakers logo" />
              <img src={beConnectedLogo} alt="Be connected logo" />
              <img src={pingLogo} alt="Ping Logo" />
              <img src={stealthLogo} alt="StealthSeminar Logo" />
              <img src={tivityHealthLogo} alt="Tivity Health Logo" />
            </div>
          </Section>
          <Link to={contactPageSlug}>
            <Button action="primary" className={styles.button}>
              {getTextCopy(content, 'page.home.secondaryButton')}
            </Button>
          </Link>
        </div>
        <Section className={styles.services}>
          <div className={styles.servicesContent}>
            <div className={styles.rocketImage}>
              <RocketSVG />
            </div>
            <div>
              <h3>{getTextCopy(content, 'page.home.tertiaryHeader')}</h3>
              <div
                className={styles.subheader}
                dangerouslySetInnerHTML={{
                  __html: getHtmlCopy(content, 'page.home.tertiarySubheader'),
                }}
              />
            </div>
          </div>
          <div className={styles.serviceItems}>
            {services.map(service => (
              <ServiceCard
                key={service.node.title}
                title={service.node.title}
                excerpt={service.node.excerpt}
                image={service.node.icon.svg.content}
                slug={servicePageSlug.replace(`{{slug}}`, service.node.slug)}
              />
            ))}
            <Card
              className={styles.viewAllServicesCard}
              linkTo={servicesPageSlug}
            >
              <h3>{getTextCopy(content, 'page.home.servicesHeader')}</h3>
              <Button action="primary" className={styles.button}>
                {getTextCopy(content, 'page.home.servicesButton')}
              </Button>
            </Card>
          </div>
        </Section>
      </Layout>
    </>
  )
}

export default IndexPage

export const query = graphql`
  {
    homePage: contentfulPage(name: { eq: "page.home" }) {
      ...pageFields
    }
    featuredContent: allContentfulHomePageFeaturedContent {
      edges {
        node {
          blurb
          type
          image {
            file {
              url
            }
          }
          videos {
            file {
              fileName
              contentType
              url
            }
          }
          relatedContent {
            slug
            __typename
          }
        }
      }
    }
    services: allContentfulService(limit: 5) {
      edges {
        node {
          id
          slug
          title
          excerpt
          icon {
            svg {
              content
            }
          }
        }
      }
    }
    servicesPage: contentfulPage(name: { eq: "page.services" }) {
      servicesPageSlug: slug
    }
    servicePage: contentfulPage(name: { eq: "page.service" }) {
      servicePageSlug: slug
    }
    contactPage: contentfulPage(name: { eq: "page.contact" }) {
      contactPageSlug: slug
    }
  }
`
