// extracted by mini-css-extract-plugin
export var browserButtons = "featured-home-page-content-module--browserButtons--f7250";
export var browserCircle = "featured-home-page-content-module--browserCircle--ddbd5";
export var contentDisplayContainerWrapper = "featured-home-page-content-module--contentDisplayContainerWrapper--c0551";
export var desktopContent = "featured-home-page-content-module--desktopContent--f2ee9";
export var featuredDesktopContainer = "featured-home-page-content-module--featuredDesktopContainer--cd837";
export var featuredHomePageContentBlurb = "featured-home-page-content-module--featuredHomePageContentBlurb--f0b1e";
export var featuredHomePageContentWrapper = "featured-home-page-content-module--featuredHomePageContentWrapper--bcf68";
export var green = "featured-home-page-content-module--green--58916";
export var mobileContent = "featured-home-page-content-module--mobileContent--18223";
export var red = "featured-home-page-content-module--red--9c63d";
export var video = "featured-home-page-content-module--video--1bb6f";
export var yellow = "featured-home-page-content-module--yellow--24292";