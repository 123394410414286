import React from 'react'
import { graphql, useStaticQuery, Link } from 'gatsby'
import * as styles from './featured-home-page-content.module.css'
import LearnMoreButton from '../../components/learnMoreButton'
import classNames from 'classnames'

const FeaturedHomePageContent = ({ content }) => {
  const data = useStaticQuery(graphql`
    {
      headerNavMenu: contentfulNavMenu(key: { eq: "nav.header" }) {
        ...navMenuFields
      }
      caseStudyPage: contentfulPage(name: { eq: "page.caseStudy" }) {
        caseStudyPageSlug: slug
      }
    }
  `)
  const { caseStudyPageSlug } = data.caseStudyPage

  const type = content.type
  const imageUrl = content.image.file.url
  const videos = content.videos
  const contentModel = content.relatedContent.__typename
  const slug = content.relatedContent.slug
  const blurb = content.blurb

  let linkTo

  switch (contentModel) {
    case 'ContentfulCaseStudy':
      linkTo = caseStudyPageSlug.replace(`{{slug}}`, slug)
      break

    default:
      throw Error(
        'Unsupported content model used as featured home page content'
      )
  }

  let contentDisplayContainer

  if (videos) {
    contentDisplayContainer = (
      <video
        className={styles.video}
        autoPlay
        autobuffer="true"
        muted
        playsInline
        loop
        poster={imageUrl}
      >
        {videos.map(video => (
          <source
            key={video.file.fileName}
            src={video.file.url}
            type={video.file.contentType}
          />
        ))}
      </video>
    )
  } else {
    contentDisplayContainer = (
      <img src={imageUrl} alt="Featured Home Page Content" />
    )
  }

  const contentDisplayContainerClasses = {
    [styles.contentDisplayContainerWrapper]: true,
    [styles.mobileContent]: type === 'mobile',
    [styles.desktopContent]: type === 'desktop',
  }

  const featuredHomePageContentWrapperClasses = {
    [styles.featuredHomePageContentWrapper]: true,
    [styles.featuredDesktopContainer]: type === 'desktop',
  }

  const browserCircleClass = {
    [styles.browserCircle]: true,
  }

  return (
    <div className={classNames(featuredHomePageContentWrapperClasses)}>
      <div className={classNames(contentDisplayContainerClasses)}>
        {type === 'desktop' && (
          <div className={styles.browserButtons}>
            <div className={classNames({...browserCircleClass, [styles.red]: true})} />
            <div className={classNames({...browserCircleClass, [styles.yellow]: true})} />
            <div className={classNames({...browserCircleClass, [styles.green]: true})} />
          </div>)}
        {contentDisplayContainer}
      </div>
      <div className={styles.featuredHomePageContentBlurb}>
        <p>{blurb}</p>
        <Link to={linkTo}>
          <LearnMoreButton />
        </Link>
      </div>
    </div>
  )
}

export default FeaturedHomePageContent
