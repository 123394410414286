import React from 'react'
import '../styles/animate.css'
import '../styles/illustrations.css'

function Rocket() {
  /* eslint-disable max-len */
  return (
    <svg
      id="f1a3512c-954b-43cd-b4a7-b2a657bfa049"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="1135.18"
      height="814.22"
      viewBox="0 0 1135.18 814.22"
      className="rocketSvg"
    >
      <defs>
        <linearGradient
          id="a846f69c-c3f1-49f3-adee-5a4e8443ee4e"
          x1="277.46"
          y1="697.11"
          x2="277.46"
          y2="669.52"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="gray" stopOpacity="0.25" />
          <stop offset="0.54" stopColor="gray" stopOpacity="0.12" />
          <stop offset="1" stopColor="gray" stopOpacity="0.1" />
        </linearGradient>
        <linearGradient
          id="b1395abd-e294-4466-9af9-40f9f1f4ad0f"
          x1="748.15"
          y1="567.16"
          x2="748.15"
          y2="544.13"
          xlinkHref="#a846f69c-c3f1-49f3-adee-5a4e8443ee4e"
        />
        <linearGradient
          id="a8b11853-451e-4181-8600-fa718044326b"
          x1="556.93"
          y1="707.16"
          x2="556.93"
          y2="42.89"
          xlinkHref="#a846f69c-c3f1-49f3-adee-5a4e8443ee4e"
        />
        <linearGradient
          id="f1ea05b4-3102-498a-ab85-05d222a08540"
          x1="456.95"
          y1="628.14"
          x2="456.95"
          y2="692.54"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#e0e0e0" />
          <stop offset="0.31" stopColor="#fccc63" />
          <stop offset="0.77" stopColor="#f55f44" />
        </linearGradient>
        <linearGradient
          id="a65a9945-4cea-45de-8969-2abf9ed9c602"
          x1="534.05"
          y1="628.14"
          x2="534.05"
          y2="692.54"
          xlinkHref="#f1ea05b4-3102-498a-ab85-05d222a08540"
        />
        <linearGradient
          id="e83da883-60d3-4fc8-a1fc-2d21e84cc652"
          x1="582.35"
          y1="628.14"
          x2="582.35"
          y2="692.54"
          xlinkHref="#f1ea05b4-3102-498a-ab85-05d222a08540"
        />
        <linearGradient
          id="e7319a41-e0d4-4be9-9de6-9ff87ed2cc66"
          x1="660.3"
          y1="628.14"
          x2="660.3"
          y2="692.54"
          xlinkHref="#f1ea05b4-3102-498a-ab85-05d222a08540"
        />
        <linearGradient
          id="a8b1f8cd-6e4b-4699-9cd1-501c51b12a87"
          x1="766.05"
          y1="409.23"
          x2="766.05"
          y2="340.57"
          xlinkHref="#a846f69c-c3f1-49f3-adee-5a4e8443ee4e"
        />
        <linearGradient
          id="ef97273a-5cfd-4109-a624-feeb24fdeadc"
          x1="331.05"
          y1="577.85"
          x2="331.05"
          y2="529.94"
          xlinkHref="#a846f69c-c3f1-49f3-adee-5a4e8443ee4e"
        />
      </defs>

      <path
        d="M1023.41,751.25a139.92,139.92,0,0,1-27.7-.37c-101.12-11.18-238.82-8-351.07,61.55C489.4,908.6,246.11,826.27,138.79,781.48c-82-34.21-124.57-118-99-196.65.36-1.11.73-2.22,1.12-3.33C57,534.76,88.76,498.18,128.09,478.79c34.28-16.9,84.33-52.19,106.6-120,9.2-28,21.82-54.34,38.21-77.3C316.79,220,408.67,128.43,566.69,129a254.66,254.66,0,0,1,81.41,13.78c57.73,19.67,109.87,59.66,149.63,115.1,25.37,35.36,63.39,68.23,117.82,70.91,75.54,3.74,147.63,38.91,201.71,101.5,1.56,1.8,3.1,3.61,4.64,5.44C1218.19,550.44,1151.31,740.07,1023.41,751.25Z"
        transform="translate(-32.41 -42.89)"
        className="backgroundPath"
      />
      <g opacity="0.7">
        <circle
          className="animated flash infinite"
          cx="277.46"
          cy="683.31"
          r="13.8"
          fill="url(#a846f69c-c3f1-49f3-adee-5a4e8443ee4e)"
        />
      </g>
      <circle cx="277.46" cy="683.31" r="13.18" opacity="0.2" />
      <circle
        className="animated flash infinite"
        cx="203.11"
        cy="414.14"
        r="5.1"
        fill="#fff"
      />
      <circle
        className="animated flash infinite"
        cx="567.11"
        cy="710.14"
        r="5.1"
        fill="#fff"
      />
      <circle
        className="animated flash infinite"
        cx="352.11"
        cy="736.14"
        r="2.85"
        fill="#fff"
      />
      <circle
        className="animated flash infinite"
        cx="983.11"
        cy="598.14"
        r="2.85"
        fill="#fff"
      />
      <circle
        className="animated flash infinite"
        cx="820.11"
        cy="342.14"
        r="2.85"
        fill="#fff"
      />
      <circle
        className="animated flash infinite"
        cx="1051.11"
        cy="467.14"
        r="2.85"
        fill="#fff"
      />
      <circle
        className="animated flash infinite"
        cx="293.11"
        cy="247.14"
        r="5.1"
        fill="#fff"
      />
      <polygon
        points="152.94 592.21 148.4 592.21 148.4 587.67 146.55 587.67 146.55 592.21 142.01 592.21 142.01 594.06 146.55 594.06 146.55 598.59 148.4 598.59 148.4 594.06 152.94 594.06 152.94 592.21"
        fill="#fff"
      />
      <polygon
        points="266.94 590.21 262.4 590.21 262.4 585.67 260.55 585.67 260.55 590.21 256.01 590.21 256.01 592.06 260.55 592.06 260.55 596.59 262.4 596.59 262.4 592.06 266.94 592.06 266.94 590.21"
        fill="#fff"
      />
      <polygon
        points="429.94 682.21 425.4 682.21 425.4 677.67 423.55 677.67 423.55 682.21 419.01 682.21 419.01 684.06 423.55 684.06 423.55 688.59 425.4 688.59 425.4 684.06 429.94 684.06 429.94 682.21"
        fill="#fff"
      />
      <polygon
        points="656.97 130.71 654.9 130.71 654.9 128.64 654.06 128.64 654.06 130.71 651.99 130.71 651.99 131.55 654.06 131.55 654.06 133.62 654.9 133.62 654.9 131.55 656.97 131.55 656.97 130.71"
        fill="#fff"
      />
      <polygon
        points="951.97 320.71 949.9 320.71 949.9 318.64 949.06 318.64 949.06 320.71 946.99 320.71 946.99 321.55 949.06 321.55 949.06 323.62 949.9 323.62 949.9 321.55 951.97 321.55 951.97 320.71"
        fill="#fff"
      />
      <polygon
        points="777.97 444.71 775.9 444.71 775.9 442.64 775.06 442.64 775.06 444.71 772.99 444.71 772.99 445.55 775.06 445.55 775.06 447.62 775.9 447.62 775.9 445.55 777.97 445.55 777.97 444.71"
        fill="#fff"
      />
      <polygon
        points="69.97 522.71 67.9 522.71 67.9 520.64 67.06 520.64 67.06 522.71 64.99 522.71 64.99 523.55 67.06 523.55 67.06 525.62 67.9 525.62 67.9 523.55 69.97 523.55 69.97 522.71"
        fill="#fff"
      />
      <polygon
        points="154.97 692.71 152.9 692.71 152.9 690.64 152.06 690.64 152.06 692.71 149.99 692.71 149.99 693.55 152.06 693.55 152.06 695.62 152.9 695.62 152.9 693.55 154.97 693.55 154.97 692.71"
        fill="#fff"
      />
      <polygon
        points="421.97 155.71 419.9 155.71 419.9 153.64 419.06 153.64 419.06 155.71 416.99 155.71 416.99 156.55 419.06 156.55 419.06 158.62 419.9 158.62 419.9 156.55 421.97 156.55 421.97 155.71"
        fill="#fff"
      />
      <polygon
        points="295.97 454.71 293.9 454.71 293.9 452.64 293.06 452.64 293.06 454.71 290.99 454.71 290.99 455.55 293.06 455.55 293.06 457.62 293.9 457.62 293.9 455.55 295.97 455.55 295.97 454.71"
        fill="#fff"
      />
      <polygon
        points="322.97 368.71 320.9 368.71 320.9 366.64 320.06 366.64 320.06 368.71 317.99 368.71 317.99 369.55 320.06 369.55 320.06 371.62 320.9 371.62 320.9 369.55 322.97 369.55 322.97 368.71"
        fill="#fff"
      />
      <g opacity="0.7">
        <circle
          cx="748.15"
          cy="555.65"
          r="11.52"
          fill="url(#b1395abd-e294-4466-9af9-40f9f1f4ad0f)"
        />
      </g>
      <circle cx="748.15" cy="555.65" r="10.67" opacity="0.2" />
      <circle cx="776.88" cy="630.76" r="11.93" opacity="0.2" />
      <g opacity="0.7">
        <path
          d="M702.38,349.92a154.77,154.77,0,0,0-17.5-72c-5.49-10.44-10.5-16.92-14.36-20.91a10.25,10.25,0,0,0-11.74-2.26,10.64,10.64,0,0,0-4.11,2.65c-5.21,5.4-9.92,12.54-14,20.13a155.43,155.43,0,0,0-18.37,74v15.48h-9.93V187c0-37.72-8.44-74.93-25-107.93-7.84-15.64-15-25.36-20.49-31.33a14.2,14.2,0,0,0-21.35,0c-7.44,8.09-14.16,18.79-20,30.16-17.39,33.72-26.22,72-26.22,110.82V366.94h-8.58V350.71a155.39,155.39,0,0,0-18.37-73.95c-4.11-7.59-8.82-14.73-14-20.13a10.27,10.27,0,0,0-12-2.18l.36.19a10.76,10.76,0,0,0-3.35,2.34c-3.85,4-8.86,10.47-14.35,20.91a154.77,154.77,0,0,0-17.5,72v269h30.64L438.78,641v66.13h25.06V641l-3.35-22.12h30.2v-245h8.58V617.82h24.47L520.23,641v66.13h25.06V641l-3.51-23.21h33L571.25,641v66.13h25.06V641l-3.51-23.21h19.54V373.91h9.93V619.28h8.05v-.37h26.62L653.6,641v66.13h25.06V641l-3.35-22.12h27.07Z"
          transform="translate(-32.41 -42.89)"
          fill="url(#a8b11853-451e-4181-8600-fa718044326b)"
        />
      </g>

      <polygon
        points="436.4 585.25 412.67 585.25 416.91 556.44 432.16 556.44 436.4 585.25"
        fill="#535461"
        className="animated shake infinite"
      />
      <g opacity="0.1">
        <polygon
          points="415.73 567.88 433.34 567.88 432.16 559.83 416.91 559.83 415.73 567.88"
          className="animated shake infinite"
        />
      </g>
      <polygon
        points="639.75 585.25 616.03 585.25 620.26 556.44 635.52 556.44 639.75 585.25"
        fill="#535461"
        className="animated shake infinite"
      />
      <g opacity="0.1">
        <polygon
          points="619.08 567.88 636.7 567.88 635.52 559.83 620.26 559.83 619.08 567.88"
          className="animated shake infinite"
        />
      </g>
      <polygon
        points="513.5 585.25 489.78 585.25 494.01 556.44 509.27 556.44 513.5 585.25"
        fill="#535461"
        className="animated shake infinite"
      />
      <g opacity="0.1">
        <polygon points="492.83 567.88 510.45 567.88 509.27 559.83 494.01 559.83 492.83 567.88" />
      </g>
      <polygon
        points="561.8 585.25 538.08 585.25 542.31 556.44 557.56 556.44 561.8 585.25"
        fill="#535461"
        className="animated shake infinite"
      />
      <g opacity="0.1">
        <polygon points="541.13 567.88 558.75 567.88 557.56 559.83 542.31 559.83 541.13 567.88" />
      </g>
      <rect x="436.4" y="318.35" width="169.46" height="6.78" fill="#535461" />
      <path
        d="M664.46,254.16a9.55,9.55,0,0,0-14.16,0c-4.93,5.26-9.39,12.21-13.28,19.6a154.77,154.77,0,0,0-17.39,72V606.6h75V344.66a154.22,154.22,0,0,0-16.57-70.14C672.85,264.36,668.11,258,664.46,254.16Z"
        transform="translate(-32.41 -42.89)"
        fill="#e0e0e0"
      />
      <path
        d="M449.39,254.16a9.55,9.55,0,0,1,14.16,0c4.94,5.26,9.39,12.21,13.29,19.6a154.89,154.89,0,0,1,17.39,72V606.6h-75V344.66a154.22,154.22,0,0,1,16.57-70.14C441,264.36,445.74,258,449.39,254.16Z"
        transform="translate(-32.41 -42.89)"
        fill="#e0e0e0"
      />
      <polygon
        points="387.25 307.33 405.05 307.42 405.05 329.36 387.25 329.36 387.25 307.33"
        fill="#535461"
      />
      <polygon
        points="444.02 307.33 461.82 307.42 461.82 329.36 444.02 329.36 444.02 307.33"
        fill="#535461"
      />
      <polygon
        points="587.22 307.33 605.01 307.42 605.01 329.36 587.22 329.36 587.22 307.33"
        fill="#535461"
      />
      <polygon
        points="643.99 307.33 661.78 307.42 661.78 329.36 643.99 329.36 643.99 307.33"
        fill="#535461"
      />
      <g opacity="0.1">
        <path
          d="M476.84,273.42c-3.9-7.39-8.35-14.34-13.29-19.6a9.51,9.51,0,0,0-11.33-2.13,10.11,10.11,0,0,1,2.86,2.13c4.93,5.26,9.39,12.21,13.28,19.6a154.75,154.75,0,0,1,17.39,72V606.24h8.48V345.43A154.88,154.88,0,0,0,476.84,273.42Z"
          transform="translate(-32.41 -42.89)"
        />
      </g>
      <g opacity="0.1">
        <path
          d="M643.8,274.14c3.89-7.39,8.35-14.34,13.28-19.6a9.92,9.92,0,0,1,3.28-2.34,9.54,9.54,0,0,0-10.9,2.34c-4.94,5.26-9.4,12.21-13.29,19.6a154.89,154.89,0,0,0-17.39,72V607h7.63V346.16A154.77,154.77,0,0,1,643.8,274.14Z"
          transform="translate(-32.41 -42.89)"
        />
      </g>
      <path
        d="M457.27,692.54h-.65A11.54,11.54,0,0,1,445.08,681V628.14h23.73V681A11.54,11.54,0,0,1,457.27,692.54Z"
        transform="translate(-32.41 -42.89)"
        fill="url(#f1ea05b4-3102-498a-ab85-05d222a08540)"
      />
      <path
        d="M534.38,692.54h-.66A11.54,11.54,0,0,1,522.19,681V628.14h23.72V681A11.54,11.54,0,0,1,534.38,692.54Z"
        transform="translate(-32.41 -42.89)"
        fill="url(#a65a9945-4cea-45de-8969-2abf9ed9c602)"
      />
      <path
        d="M582.67,692.54H582A11.54,11.54,0,0,1,570.49,681V628.14h23.72V681A11.54,11.54,0,0,1,582.67,692.54Z"
        transform="translate(-32.41 -42.89)"
        fill="url(#e83da883-60d3-4fc8-a1fc-2d21e84cc652)"
      />
      <path
        d="M660.63,692.54H660A11.54,11.54,0,0,1,648.44,681V628.14h23.72V681A11.54,11.54,0,0,1,660.63,692.54Z"
        transform="translate(-32.41 -42.89)"
        fill="url(#e7319a41-e0d4-4be9-9de6-9ff87ed2cc66)"
      />
      <path
        d="M566.34,49.64a13.2,13.2,0,0,0-20.21,0c-7,7.88-13.4,18.29-19,29.37-16.46,32.84-24.82,70.12-24.82,107.92V605.54h107V185.25c0-36.73-8-73-23.64-105.1C578.32,64.92,571.55,55.45,566.34,49.64Z"
        transform="translate(-32.41 -42.89)"
        fill="#eee"
      />
      <rect
        x="494.79"
        y="53.25"
        width="55.84"
        height="25.59"
        rx="11"
        fill="#535461"
      />
      <g opacity="0.1">
        <rect x="470.48" y="158.35" width="105.91" height="4.11" />
      </g>
      <path
        d="M793.45,354.22h0a34.62,34.62,0,0,0-10.22-9h0a34.33,34.33,0,1,0-17.17,64.07c.73,0,1.46,0,2.17-.08a34.32,34.32,0,0,0,25.23-54.93Z"
        transform="translate(-32.41 -42.89)"
        fill="url(#a8b1f8cd-6e4b-4699-9cd1-501c51b12a87)"
      />
      <path
        d="M798.5,374.9a32.45,32.45,0,0,1-30.4,32.38c-.67,0-1.36.07-2.05.07a32.46,32.46,0,1,1,16.23-60.56h0a32.59,32.59,0,0,1,9.66,8.56h0A32.3,32.3,0,0,1,798.5,374.9Z"
        transform="translate(-32.41 -42.89)"
        opacity="0.2"
      />
      <path
        d="M758.06,354.31a34.88,34.88,0,0,0-4.84,2.55,7.61,7.61,0,0,0-2.59,2.32,3,3,0,0,0-.06,3.31c.79,1.08,2.31,1.24,3.65,1.32a9.34,9.34,0,0,0,4.18-.42,9,9,0,0,0,2.44-1.77l2.79-2.53c1-.88,2-2.09,1.61-3.33a3,3,0,0,0-2.37-1.6,9.92,9.92,0,0,0-7,1.23"
        transform="translate(-32.41 -42.89)"
        opacity="0.1"
      />
      <g opacity="0.1">
        <circle
          className="animated flash infinite"
          cx="714"
          cy="344.82"
          r="1.71"
        />
      </g>
      <g opacity="0.1">
        <path
          d="M792,355.35h0a8.54,8.54,0,1,1-9.67-8.56h0A32.59,32.59,0,0,1,792,355.35Z"
          transform="translate(-32.41 -42.89)"
        />
      </g>
      <g opacity="0.1">
        <path
          d="M792.58,393.58a32.37,32.37,0,0,1-24.48,13.7c0-.45-.06-.9-.06-1.36a15.38,15.38,0,0,1,24.54-12.34Z"
          transform="translate(-32.41 -42.89)"
        />
      </g>
      <path
        d="M350.17,539.47h0a24.15,24.15,0,0,0-7.14-6.32h0a24,24,0,1,0-12,44.7c.51,0,1,0,1.52-.05a24,24,0,0,0,17.6-38.33Z"
        transform="translate(-32.41 -42.89)"
        fill="url(#ef97273a-5cfd-4109-a624-feeb24fdeadc)"
      />
      <path
        d="M353.69,553.9a22.64,22.64,0,0,1-21.21,22.59c-.47,0-1,0-1.43,0a22.64,22.64,0,1,1,11.32-42.25h0a22.67,22.67,0,0,1,6.74,6h0A22.52,22.52,0,0,1,353.69,553.9Z"
        transform="translate(-32.41 -42.89)"
        opacity="0.2"
      />
      <path
        d="M325.47,539.54a23.5,23.5,0,0,0-3.37,1.77,5.54,5.54,0,0,0-1.81,1.62,2.13,2.13,0,0,0,0,2.31,3.23,3.23,0,0,0,2.54.92,6.51,6.51,0,0,0,2.92-.29,6.29,6.29,0,0,0,1.71-1.24l1.94-1.76c.68-.62,1.42-1.46,1.12-2.32a2,2,0,0,0-1.65-1.12,6.87,6.87,0,0,0-4.87.86"
        transform="translate(-32.41 -42.89)"
        opacity="0.1"
      />
      <g opacity="0.1">
        <circle
          className="animated flash infinite"
          cx="284.93"
          cy="519.94"
          r="1.19"
        />
      </g>
      <g opacity="0.1">
        <circle
          className="animated flash infinite"
          cx="309.16"
          cy="512.4"
          r="4.37"
        />
      </g>
      <g opacity="0.1">
        <path
          d="M349.12,540.26h0a6,6,0,1,1-6.75-6h0A22.67,22.67,0,0,1,349.12,540.26Z"
          transform="translate(-32.41 -42.89)"
        />
      </g>
      <g opacity="0.1">
        <path
          d="M349.56,566.93a22.59,22.59,0,0,1-17.08,9.56c0-.31,0-.63,0-1a10.73,10.73,0,0,1,17.12-8.61Z"
          transform="translate(-32.41 -42.89)"
        />
      </g>
      <polyline
        points="837.69 539.42 859.49 551.02 842.47 570.06"
        fill="none"
      />
      <circle
        className="animated flash infinite"
        cx="954.9"
        cy="428.23"
        r="1.84"
        fill="#fff"
      />
    </svg>
  )
  /* eslint-enable */
}

export default Rocket
